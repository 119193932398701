<template>
    <v-main>
        <app-menu/>
        <v-toolbar>
            <v-tabs v-model="tab"
                next-icon="mdi-arrow-right-bold-box-outline"
                prev-icon="mdi-arrow-left-bold-box-outline"
                show-arrows>
                <v-tabs v-model="tab" background-color="primary" grow dark>
                    <v-tab>Aprobación de comentarios por producto</v-tab>
                </v-tabs>
            </v-tabs>
        </v-toolbar>
        <v-card min-height="100%">
            <AprobacionComentario v-if="tab==0" />
        </v-card>
    </v-main>
</template>
<script>
import AppMenu from '../../components/admin/MenuAdmin.vue';
import AprobacionComentario from '../../components/admin/AprobacionComentarios.vue'
export default {
    data(){
        return{
            tab:"",
        }
    },
    components:{
        "app-menu": AppMenu,
        AprobacionComentario
    }
}
</script>