<template>
    <v-container>
        <v-card>
            <v-card-title>
                <v-text-field color="orange" v-model="search" label="Buscar por producto" prepend-icon="mdi-text-box-search-outline"></v-text-field>
            </v-card-title>
            <v-data-table
            no-data-text="No hay comentarios disponibles"
            no-results-text="No hay coincidencias disponibles"
            :footer-props="{'items-per-page-options':[10,20,30,40]}"
            :headers="headers" :items="comentariosProducto" :search="search"
            >
            <template v-slot:[`item.value`]="{ item }" class="mx-auto">
                <center>
                    <div style="color:#1988EA"> {{item.value}}</div>
                </center>
            </template>
            <template v-slot:[`item.opinion`]= "{ item }">
                <v-card dark elevation="0" >
                    <div class="text-left container" style="border: 0px  #208DEE; border-radius:5px; box-shadow:10px 5px 10px 0px #208DEE;">
                        {{item.opinion}}
                    </div>
                </v-card>
            </template>
            <template v-slot:[`item.aprobacion`]="{ item }">
                <v-radio-group style="margin-left: 32%;" v-model="item.aprobacion">
                    <v-radio color="#4EE169" label="Si" :value="true" @click="aprobar(item)"></v-radio>
                    <v-radio color="error" label="No" :value="false"></v-radio>
                </v-radio-group>
            </template>
            <template v-slot:[`item.calificacion`]= " { item }">
                <center>
                    <v-rating class="ratingCursor"
                        v-model="item.calificacion"
                        background-color="#6E6F64 lighten-4"
                        color="yellow"
                        half-increments
                        size="20"
                      ></v-rating>
                </center>
            </template>
            </v-data-table>
        </v-card>
    </v-container>
</template>
<style>
    .v-card .v-data-table-header th[role=columnheader] {
        font-size: 13px !important;
        text-align: center !important;
    }
    .ratingCursor{
        cursor:  not-allowed;
        pointer-events:none;
    }
</style>
<script>
import axios from 'axios';
import config from '../../json/config.json'
export default {
    data(){
        return{
            search:"",
            headers:[
                {text: "Producto",value:"value"},
                {text: "Nombre",value:"nombre"},
                {text: "Comentario",value:"opinion"},   
                {text: "Aprobación",value:"aprobacion"},
                {text: "Calificación",value:"calificacion"},
            ],
            comentariosProducto:[],
            aprobarComentario:{},


        }
        
    },
    created(){
        this.validaLogin();
        this.comentarios();
    },
    methods:{
        async validaLogin(){
          this.user = await axios.get(config.apiAmoresens + "/user/userByTokenAdmin", 
          {
            'headers': { 'token': this.$cookie.get('token') }
          }).then(res=>{return res.data;})
          .catch(err=>{return err;});
          if (this.user.status == "success") {
            this.user = this.user.user;
            this.isLogged = true; 
          }else if(this.user.status == "unauthorized"){ 
            this.isLogged = false;
          } 
        },
        async comentarios(){
            await axios.get(config.apiAmoresens + '/cal/comentariosAdmin',
            {'headers': { 'token': this.$cookie.get('token') }})
            .then(res=>{
                this.comentariosProducto = res.data.data;
            }).catch(err=>{return err})
        },
        async aprobar(item){
            this.aprobarComentario._id = item._id;
            this.aprobarComentario.m_product_id = item.m_product_id;
            this.aprobarComentario.ad_user_id = item.ad_user_id
            console.log(this.aprobarComentario);
            await axios.put(config.apiAmoresens + '/cal/aprobar',this.aprobarComentario,
            {'headers': { 'token': this.$cookie.get('token') }})
            .then(res=>{return res})
            .catch(err=>{return err})
        }

    }
}
</script>